import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
      <marquee>
          <p>
            I love you sayang
          </p>
        </marquee>
      </header>
    </div>
  );
}

export default App;
